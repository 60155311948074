import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faThumbsUp, faMoneyBillTrendUp, faEarthEurope, faServer, faPeopleGroup, faFire } from '@fortawesome/free-solid-svg-icons'

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'


import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Home.min.css'
import './MHome.min.css'



export default function Home() {


    return (
        <div className='mainCon' id='mainCon'>
            <section className='home--sec_start'>
                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />

                <h1 className="noselect">Trainer-App für die THW Grundausbildung</h1>
                <div className='cta_container'>
                    <CTAButton className='start_cta' hideArrow={true} title="Prüfungsmodus" onclick={() => lP('/train/randomexam')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Zufällige Fragen" onclick={() => lP('/train/randomquest')} />
                    {/* <CTAButton className='start_cta' hideArrow={true} title="Fragen nach Kategorie" onclick={() => lP('/train/categoryselect')} /> */}
                    <CTAButton className='start_cta cta_markred' hideArrow={true} title="Zum FüUF26 Lehrgang" onclick={() => lP('/fueuf26/randomexam')} />
                    <a onClick={() => lP('/about')} className='abig noselect'><b>Über die App</b></a>
                </div>
            </section>

            <MainFooter />
        </div>
    )
}