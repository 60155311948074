
import { React } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import '../../main.min.css'
import './CTAButton.min.css'
import './MCTAButton.min.css'
import { lP, oP } from '../../main.js'


export default function CTAButton(props) {

    return (
        // <div className='mainCon'>
        <div className={'ctabutton noselect ' + props.className} onClick={() => props.onclick()}>
            <font>{props.title}</font>
            {props.hideArrow != true ? <FontAwesomeIcon id="ctaarrow" icon={faArrowRight} /> : ""}
        </div>
        // </div>
    )
}