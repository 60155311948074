import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import MainHeader from '../../../components/MainHeader/MainHeader.js'
import MainFooter from '../../../components/MainFooter/MainFooter.js'
import CTAButton from '../../../components/CTAButton/CTAButton.js'


import { lP, oP, sT } from '../../../main.js'

import '../../../main.min.css'
import './CategorySelect.min.css'
import './MCategorySelect.min.css'



export default function CategorySelect() {


    return (
        <div className='mainCon' id='mainCon'>
            <section className='catesel--sec_start'>
                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />
                <a className="backToPageBtn" href="/news">
                    <FontAwesomeIcon className="downarricon" icon={faChevronLeft} />
                    &nbsp;ZURÜCK
                </a><br /><br /><br />
                <h1 className="noselect">Wähle die Kategorie</h1>
                <div className='cta_container'>
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 1: THW im Zivilschutz" onclick={() => lP('/train/categoryquest?cat=1')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 2: Arbeitssicherheit" onclick={() => lP('/train/categoryquest?cat=2')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 3: Leinen, Seile, Ketten" onclick={() => lP('/train/categoryquest?cat=3')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 4: Umgang mit Leitern" onclick={() => lP('/train/categoryquest?cat=4')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 5: Strom und Beleuchtung" onclick={() => lP('/train/categoryquest?cat=5')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 6: Metall, Holz, Gestein" onclick={() => lP('/train/categoryquest?cat=6')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 7: Bewegen von Lasten" onclick={() => lP('/train/categoryquest?cat=7')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 8: Arbeiten am und auf Wasser" onclick={() => lP('/train/categoryquest?cat=8')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 9: Einsatzgrundlagen" onclick={() => lP('/train/categoryquest?cat=9')} />
                    <CTAButton className='start_cta' hideArrow={true} title="Kapitel 10: Rettung und Bergung" onclick={() => lP('/train/categoryquest?cat=10')} />
                </div>
            </section>

            <MainFooter />
        </div>
    )
}