import { React } from 'react'

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'

import './Page404.min.css'
import './MPage404.min.css'
import '../../main.min.css'

import { lP } from '../../main.js'

export default function Page404() {

    return (
        <div className='mainCon' id='mainCon'>
            <section className='page404--sec_start'>
                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />
                <h1>Du bist auf dem Holzweg!</h1>
                <h2>ERROR 404 - Seite nicht gefunden</h2>
                <div className='infocontainer'>
                    <p>
                        Kehre entweder zur vorherigen Seite oder zur <a href="/">Startseite</a> zurück.<br />
                        Sollte der Fehler erneut auftreten, kannst du uns gerne kontaktieren.<br />
                        Am besten erreichst du uns über unseren Supportbereich<br />
                        oder alternativ per Mail an <a href="mailto:info@tonetwork.de">info@tonetwork.de</a>.<br />
                        Wir stehen dir gerne zur Seite😉
                    </p>
                    {/* <img id="repairmanimg" src={hv_repairman} alt="Human-Vektor Grafik" /> */}
                </div>
                <br />
                <CTAButton className='start_cta' title="Zum Support" onclick={() => lP('https://tonetwork.de/support.php')} />
                <a href="/" className='backbtn'>Zur Startseite</a>
            </section>
            <MainFooter />
        </div>
    )
}