
import { React, useState } from 'react'

import logo_breit from '../../assets/pictures/logos/GAT-Logo512.png'
import ton_logo from '../../assets/pictures/logos/ton_logo-breit-white_cut-small.webp'
// import socialmedia_placeholder from '../../assets/pictures/social-media-icons.png'

import { lP, oP, sT, URL_MAINAPI, API_TOKEN } from '../../main.js'


import './MainFooter.min.css'
import './MMainFooter.min.css'
import '../../main.min.css'

var alternativeBG = `.mainfooter {
    background-color: rgba(var(--ci_color_primary_rgb), 0.2);
    // background-color: var(--ci_color_mainbg);
    border-radius: 0;
}

.mainfooter .footerimage {
    display: none;
}`

export default function MainFooter(props) {


    return (
        <div className='mainfooterdiv'>

            {/* <style>{(props.altBG == true) ? alternativeBG : ""}</style> */}
            <style>{alternativeBG}</style>

            <div className='mainfooter'>
                {/* <img src={footer_image} className='footerimage' /> */}

                <div className='footercont'>
                    <div className='logocont'>
                        <img src={logo_breit} alt="thwGAT Logo" /><br /><br />
                        <b>Ein Projekt von</b><br /><br />
                        <img className="tonlogo" src={ton_logo} alt="ToNetwork Logo" onClick={() => oP("https://tonetwork.de")} />
                    </div>
                    <div className="footerlinkscont">
                        <div className='footerlinks linkcont1'>
                            <b>Shortcuts</b><br /><br />
                            <a href='/'>Startseite</a><br /><br />
                            <a href='https://tonetwork.de/datenschutz.php'>Impressum+Datenschutz</a><br /><br />
                            <a href='https://tonetwork.de/support.php'>Support</a><br /><br />
                            <a href='/about'>Über die App</a><br /><br />
                        </div>
                    </div>

                </div>
                <p>©2023 thwGAT (Ein Projekt der <a href="https://tonetwork.de" target="_blank">ToNetwork UG</a>)<br /><br />
                    Fragen: v3.4 Dez 2022 (Urheber: THW)</p>
            </div>
        </div>
    )
}