import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

import MainHeader from '../../../components/MainHeader/MainHeader'
import MainFooter from '../../../components/MainFooter/MainFooter'
import CTAButton from '../../../components/CTAButton/CTAButton'

import jsondata from '../../../assets/questions.json'

import { lP, oP, sT } from '../../../main.js'

// import '../../../main.min.css'

import './RandomQuest.min.css'
import './MRandomQuest.min.css'


export default function RandomQuest() {


    var [loadedQuest, setLoadedQuest] = useState(1)

    var [questText, setQuestText] = useState("")

    var [questData, setQuestData] = useState("")

    var [answ1State, setAnsw1State] = useState(0)
    var [answ2State, setAnsw2State] = useState(0)
    var [answ3State, setAnsw3State] = useState(0)
    var [answ1Text, setAnsw1Text] = useState("")
    var [answ2Text, setAnsw2Text] = useState("")
    var [answ3Text, setAnsw3Text] = useState("")
    var [answerOpen, setAnswerOpen] = useState(0)
    var [answ1Corr, setAnsw1Corr] = useState(0)
    var [answ2Corr, setAnsw2Corr] = useState(0)
    var [answ3Corr, setAnsw3Corr] = useState(0)
    var [yourAnswCorr, setYourAnswCorr] = useState(0)
    var [questid, setQuestid] = useState(0)
    var [questCount, setQuestCount] = useState(0)
    var [allQuestsAnsw, setAllQuestsAnsw] = useState(0)

    var [data, setData] = useState({})
    var [usedQuest, setUsedQuest] = useState([])


    function showAnswer() {
        // document.querySelector('#answ1corr').style.display = 'block'
        // document.querySelector('#answ2corr').style.display = 'block'
        // document.querySelector('#answ3corr').style.display = 'block'
        document.querySelector('#result_text').style.display = 'block'
        setAnswerOpen(1)


        let wrongcount = 0

        if (answ1State == answ1Corr) {
            document.querySelector('#answ1text').style.color = 'var(--ci_color_green)'
            document.querySelector('#answ1chm').style.borderColor = 'var(--ci_color_green)'

        } else {
            wrongcount++
            document.querySelector('#answ1text').style.color = 'var(--ci_color_red)'
            document.querySelector('#answ1chm').style.borderColor = 'var(--ci_color_red)'

            document.querySelector('#answ1corr').style.display = 'block'
        }

        if (answ2State == answ2Corr) {
            document.querySelector('#answ2text').style.color = 'var(--ci_color_green)'
            document.querySelector('#answ2chm').style.borderColor = 'var(--ci_color_green)'

        } else {
            wrongcount++
            document.querySelector('#answ2text').style.color = 'var(--ci_color_red)'
            document.querySelector('#answ2chm').style.borderColor = 'var(--ci_color_red)'

            document.querySelector('#answ2corr').style.display = 'block'

        }
        if (answ3State == answ3Corr) {
            document.querySelector('#answ3text').style.color = 'var(--ci_color_green)'
            document.querySelector('#answ3chm').style.borderColor = 'var(--ci_color_green)'

        } else {
            wrongcount++
            document.querySelector('#answ3text').style.color = 'var(--ci_color_red)'
            document.querySelector('#answ3chm').style.borderColor = 'var(--ci_color_red)'
            document.querySelector('#answ3corr').style.display = 'block'

        }

        window.localStorage.setItem('thwgat-randomquest-questcount', parseInt(questCount) + 1)
        setQuestCount(parseInt(questCount) + 1)

        if (wrongcount == 0) {
            setYourAnswCorr(1)
            let newusedquest = usedQuest
            newusedquest.push('' + questid)
            setUsedQuest(newusedquest)
            window.localStorage.setItem('thwgat-randomquest-usedquests', newusedquest)

        } else {
            setYourAnswCorr(0)
        }

    }

    function getRandomInt(max) {
        let newint = Math.floor(Math.random() * max)
        if (usedQuest.length >= jsondata.length) {
            setAllQuestsAnsw(1)
            console.log("Fragenlimit erreicht");
        } else {
            while (usedQuest.includes('' + newint)) {
                newint = Math.floor(Math.random() * max)
            }
        }
        return newint
    }

    function getOneQuest() {
        setAnswerOpen(0)
        setAnsw1State(0)
        setAnsw2State(0)
        setAnsw3State(0)

        try {
            document.querySelector('#answ1corr').style.display = 'none'
            document.querySelector('#answ2corr').style.display = 'none'
            document.querySelector('#answ3corr').style.display = 'none'
            // document.querySelector('#result_text').style.display = 'none'
            document.querySelector('#result_text').style.display = 'block'

            document.querySelector('#answ1text').style.color = 'black'
            document.querySelector('#answ2text').style.color = 'black'
            document.querySelector('#answ3text').style.color = 'black'

            document.querySelector('#answ1chm').style.borderColor = 'var(--ci_color_primary)'
            document.querySelector('#answ2chm').style.borderColor = 'var(--ci_color_primary)'
            document.querySelector('#answ3chm').style.borderColor = 'var(--ci_color_primary)'
        } catch (e) { }

        if (usedQuest.length == jsondata.length) {
            setAllQuestsAnsw(1)
            console.log('Alle Fragen beantwortet')
        } else {

            console.log(usedQuest)

            let newquestid = getRandomInt(jsondata.length)
            // let newquestid = 0
            setQuestid(newquestid)
            // console.log(jsondata[questid])

            setQuestText(jsondata[newquestid]['question'])
            setQuestData(jsondata[newquestid]['question_id'])
            let answarray = jsondata[newquestid]['answers']
            let suffledanswarray = answarray.sort((a, b) => 0.5 - Math.random());

            setAnsw1Text(suffledanswarray[0]['answer_option'])
            setAnsw2Text(suffledanswarray[1]['answer_option'])
            setAnsw3Text(suffledanswarray[2]['answer_option'])

            setAnsw1Corr(suffledanswarray[0]['correct'])
            setAnsw2Corr(suffledanswarray[1]['correct'])
            setAnsw3Corr(suffledanswarray[2]['correct'])
        }

    }

    function resetStore() {
        try {
            window.localStorage.removeItem('thwgat-randomquest-usedquests')
            window.localStorage.removeItem('thwgat-randomquest-questcount')
            setUsedQuest([])
            setQuestCount(0)
            setAllQuestsAnsw(0)
            usedQuest = []
            questCount = 0
            allQuestsAnsw = 0
            console.log('Fragenstand zurückgesetzt')
            lP('/train/randomquest')
        } catch (e) { }
    }

    var [infoState, setInfoState] = useState(0)
    function toggleInfo() {
        if (infoState == 0) {
            document.querySelector('.questinfo').style.display = 'block'
            setInfoState(1)
        } else {
            document.querySelector('.questinfo').style.display = 'none'
            setInfoState(0)
        }

    }

    var allowexit = 0;
    // window.onbeforeunload = confirmExit;
    // function confirmExit() {
    //     if (!allowexit) {
    //         return "You have attempted to leave this page. Are you sure?"
    //     }
    // }
    function closeExam() {
        allowexit = 1;
        lP('/')
    }

    useEffect(() => {
        document.querySelector('.questinfo').style.display = 'none'

        try {
            console.log(window.localStorage.getItem('thwgat-randomquest-usedquests').split(','))
            setUsedQuest(window.localStorage.getItem('thwgat-randomquest-usedquests').split(','))
            setQuestCount(parseInt(window.localStorage.getItem('thwgat-randomquest-questcount')))
            usedQuest = window.localStorage.getItem('thwgat-randomquest-usedquests').split(',')
            questCount = parseInt(window.localStorage.getItem('thwgat-randomquest-questcount'))

        } catch (e) { }
        getOneQuest()
    }, [])


    return (
        <div className='mainCon' id='mainCon'>
            <section className='sec_start'>
                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />
                <h1 className="noselect">Zufällige Fragen</h1>
                <br />
                {!loadedQuest ? <div>Frage wird geladen...</div> : allQuestsAnsw ?
                    <div id='allquestsanswcont'>
                        <FontAwesomeIcon className='successicon' style={{ color: 'var(--ci_color_green)' }} icon={faCheck} /><br />
                        Du warst fleißig und hast alle Fragen richtig beantwortet!<br />
                        Möchtest du nochmal alle Fragen durchgehen?<br /><br />
                        <a onClick={() => resetStore()}>Neustart</a>
                    </div>
                    :
                    <div className='questcont'>
                        <h3 className='questtext'>
                            {questText}<br />
                            <a id="info_toggle" onClick={() => toggleInfo()}>▶ Infos</a>
                            <p className='questinfo'>FrageID: {questData}<br />
                                Richtig beantwortet: {usedQuest.length} von {jsondata.length}<br />
                                Gesamt beantwortet: {questCount}<br />
                                <a onClick={() => resetStore()}>Alles zurücksetzen</a></p>
                        </h3>
                        <br />


                        <label className="chb_container">
                            <input type="checkbox" className="checkbox" name="answ1" id="answ1" value="1" checked={answ1State} readOnly={answerOpen} onChange={(e) => !answerOpen ? setAnsw1State(e.target.checked) : console.log()}></input>
                            <span className="checkmark" id="answ1chm"></span>
                            <font id="answ1text" className='noselect'>{answ1Text}</font>
                            <font id="answ1corr" style={{ display: 'none' }} className='noselect'>Richtig wäre: {answ1Corr ? <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_green)' }} icon={faCheck} /> : <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_red)' }} icon={faXmark} />}</font>

                        </label>
                        <label className="chb_container">
                            <input type="checkbox" className="checkbox" name="answ2" id="answ2" value="1" checked={answ2State} readOnly={answerOpen} onChange={(e) => !answerOpen ? setAnsw2State(e.target.checked) : console.log()}></input>
                            <span className="checkmark" id="answ2chm"></span>
                            <font id="answ2text" className='noselect'>{answ2Text}</font>
                            <font id="answ2corr" style={{ display: 'none' }} className='noselect'>Richtig wäre: {answ2Corr ? <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_green)' }} icon={faCheck} /> : <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_red)' }} icon={faXmark} />}</font>

                        </label>
                        <label className="chb_container">
                            <input type="checkbox" className="checkbox" name="answ3" id="answ3" value="1" checked={answ3State} readOnly={answerOpen} onChange={(e) => !answerOpen ? setAnsw3State(e.target.checked) : console.log()}></input>
                            <span className="checkmark" id="answ3chm"></span>
                            <font id="answ3text" className='noselect'>{answ3Text}</font>
                            <font id="answ3corr" style={{ display: 'none' }} className='noselect'>Richtig wäre: {answ3Corr ? <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_green)' }} icon={faCheck} /> : <FontAwesomeIcon className='' style={{ color: 'var(--ci_color_red)' }} icon={faXmark} />}</font>
                        </label>

                        <font id='result_text'>{answerOpen == 0 ? " " : yourAnswCorr ? <font style={{ color: 'var(--ci_color_green)' }}>🥳 Alles richtig!</font> : <font style={{ color: 'var(--ci_color_red)' }}>☹️ Leider falsch</font>}</font>


                    </div>
                }

                {
                    allQuestsAnsw ? "" :
                        !answerOpen ?
                            <CTAButton className='result_cta' hideArrow={true} title="Lösung anzeigen" onclick={() => showAnswer()} /> :
                            <CTAButton className='result_cta' hideArrow={true} title="Nächste Frage" onclick={() => getOneQuest()} />
                }
                <br /><br />
                <div className='closeexamcont noselect'><a id="closeexambtn" onClick={() => closeExam()}>Beenden</a></div>

            </section >

            {/* <MainFooter /> */}
        </div >
    )
}