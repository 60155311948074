
import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars, faDoorOpen, faUserCheck } from '@fortawesome/free-solid-svg-icons'

import logo_breit from '../../assets/pictures/logos/GAT-Logo512.png'
import logo_app from '../../assets/pictures/logos/GAT-App-Logo512.png'

import { lP, oP, sT } from '../../main.js'

import './MainHeader.min.css'
import './MMainHeader.min.css'
import '../../main.min.css'

import Cookies from 'universal-cookie'
import MainMenu from '../MainMenu/MainMenu'

const cookies = new Cookies()


export default function MainHeader(props) {

    var [cur_userid, setCur_userid] = useState("")
    var [menuopenState, setMenuOpenState] = useState(false)

    useEffect(() => {
        setCur_userid(cookies.get('userid'))
        console.log(cur_userid)
    }, [])
    var hideProfile = props.hideProfile
    var hideMenu = props.hideMenu
    // hideMenu=true
    function stateHandler() {
        setMenuOpenState(!menuopenState)
    }
    function closeHandler() {
        setMenuOpenState(false)
    }

    useEffect(() => {
        // TODO - REGEX
        try {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
                document.querySelector(".orientationModal").style.display = "block"
            } else {
                document.querySelector(".orientationModal").style.display = "none"
            }
        } catch (e) {
            console.log(e)
        }


        var menumodal = document.querySelector(".mainmenu")
        var menumodalbg = document.querySelector(".mainmenumodalbg")
        const clicklistener = event => {
            if (event.target != menumodal && event.target == menumodalbg) {
                console.log("close")
                setMenuOpenState(false)
                // event.preventDefault()
            }
        }
        const orientationlistener = event => {
            console.log("rotate")

            //TODO - REGEX
            try {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
                    document.querySelector(".orientationModal").style.display = "block"
                } else {
                    document.querySelector(".orientationModal").style.display = "none"
                }
            } catch (e) {
                console.log(e)
            }

        }
        document.addEventListener("click", clicklistener)
        window.addEventListener('orientationchange', orientationlistener)

        return () => {
            document.removeEventListener("click", clicklistener)
            document.removeEventListener("orientationchange", orientationlistener)
        }

    })

    var cur_profileIcon = (cur_userid != "" && cur_userid != undefined) ? faUserCheck : faUser
    var cur_profileColor = (cur_userid != "" && cur_userid != undefined) ? "var(--ci_color_secondary)" : "var(--ci_color_primary)"

    return (
        <div>
            {/* <div className="DEVinfo">
                🛠️ ACHTUNG: ENTWICKLUNGSVERSION 🛠️
            </div> */}
            <div className="orientationModal">
                Bitte nutze unsere Webseite nur im Hochformat.
            </div>
            <MainMenu showMenu={menuopenState} statehandler={stateHandler} closehandler={closeHandler} />
            <div className='mainheaderdiv noselect'>
                <div className='mainheaderleft'>
                    <img className='mainlogo' onClick={() => { window.location.href = "/" }} src={logo_breit} style={{ cursor: 'pointer' }} alt="Mentory Logo" />
                    <img className='mainlogo mobilemainlogo' onClick={() => { window.location.href = "/" }} src={logo_app} style={{ cursor: 'pointer' }} alt="Mentory Logo" />
                    <h2 className="headerpagetitle">{props.pagetitle}</h2>
                </div>

                <div className='mainheaderright'>
                    <div>
                        {/* {(hideProfile) ? <div></div> : <FontAwesomeIcon className="headerbtnicon profileicon" style={{ color: cur_profileColor }} icon={cur_profileIcon} onClick={() => (cur_userid != "" && cur_userid != undefined) ? lP("/profil") : lP("/login")} />} */}
                        {(hideMenu || menuopenState) ? <div></div> : <FontAwesomeIcon className="headerbtnicon menuicon" icon={faBars} onClick={() => { setMenuOpenState(!menuopenState) }} />}
                    </div>
                </div>
            </div>
        </div>

    )
}