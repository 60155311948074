import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { React, useEffect, useState } from 'react'

import './App.min.css'
import './MApp.min.css'
import './pages/Train/Quest.min.css'


import Cookies from 'universal-cookie'

import About from './pages/About/About'
import Home from './pages/Home/Home'
import Page404 from './pages/Page404/Page404'
import RandomQuest from './pages/Train/RandomQuest/RandomQuest'
import RandomExam from './pages/Train/RandomExam/RandomExam'
import CategoryQuest from './pages/Train/CategoryQuest/CategoryQuest'
import CategorySelect from './pages/Train/CategorySelect/CategorySelect.js'
import FUEUF26_RandomExam from './pages/FUEUF26/RandomExam/FUEUF26_RandomExam'

import { generateUUID, getBrowser } from './main.js'

const cookies = new Cookies()

export default function App() {
    useEffect(() => {
        try {
            window.screen.orientation.lock("portrait")
        } catch (e) {
            console.log(e)
        }
    }, [])

    let cur_userid = cookies.get('userid')
    let loggedin = (cur_userid != "" && cur_userid != undefined)

    var agentinfo = getBrowser(navigator.userAgent)
    var location = window.location

    // console.log(agentinfo.browser)
    // console.log(agentinfo.agent)
    // console.log(location.pathname)

    // window.localStorage.removeItem('thwgat-quest-id')
    var personalid = window.localStorage.getItem('thwgat-quest-id')
    // console.log(personalid)
    if (personalid == null) {
        let newid = generateUUID()
        window.localStorage.setItem('thwgat-quest-id', newid)
        personalid = newid
    }


    return (
        <Router>
            <Switch>
                <Route path="/about" component={About} />
                <Route path="/train/randomquest" component={RandomQuest} />
                <Route path="/train/randomexam" component={RandomExam} />
                <Route path="/train/categoryselect" component={CategorySelect} />
                <Route path="/train/categoryquest" component={CategoryQuest} />
                <Route path="/train*" component={Page404} />
                <Route path="/fueuf26/randomexam" component={FUEUF26_RandomExam} />
                <Route path="/fueuf26*" component={Page404} />

                <Route path="/" component={Home} />
                <Route path="/*" component={Page404} />
                <Route component={Page404} />
            </Switch>
        </Router>
    )
}

