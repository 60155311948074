
import { React, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPeopleGroup, faHeadset, faScaleBalanced, faHome, faLinesLeaning, faGraduationCap, faBookOpenReader, faDatabase } from '@fortawesome/free-solid-svg-icons'

// import logo_breit from '../../assets/pictures/logos/GAT-Logo512.png'

import { lP, oP, sT, URL_MAINAPI, API_TOKEN } from '../../main.js'


import './MainMenu.min.css'
import './MMainMenu.min.css'
import '../../main.min.css'


export default function MainMenu(props) {
    var showMenu = props.showMenu


    return (
        (showMenu != true) ? <div></div> :
            <div className='mainmenudiv'>
                <div className="mainmenumodalbg"></div>

                <div className='mainmenu noselect'>
                    <FontAwesomeIcon className="mainmenucloseicon" icon={faXmark} onClick={() => { props.statehandler() }} />
                    <h1 className='mainmenuh1'>Menü</h1>
                    <br /><br /><br />
                    <h2 className='mainmenuh2'>Allgemein</h2>
                    <div className="mainmenutilecont">
                        <div className="mainmenutile" onClick={() => { lP("/") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faHome} />
                            Start
                        </div>
                        <div className="mainmenutile" onClick={() => { lP("/about") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faPeopleGroup} />
                            About
                        </div>
                    </div>
                    <h2 className='mainmenuh2'>Apps</h2>
                    <div className="mainmenutilecont">
                        <div className="mainmenutile" onClick={() => { lP("/train/randomexam") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faGraduationCap} />
                            Prüfung
                        </div>
                        <div className="mainmenutile" onClick={() => { lP("/train/randomquest") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faLinesLeaning} />
                            Zufallsfragen
                        </div>
                        <div className="mainmenutile" onClick={() => { lP("/train/categoryselect") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faDatabase} />
                            Fragenkatalog
                        </div>
                        {/* <div className="mainmenutile" onClick={() => { lP("/fueuf26/randomexam") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faBookOpenReader} />
                            FüUF26
                        </div> */}
                    </div>
                    <h2 className='mainmenuh2'>Weiteres</h2>
                    <div className="mainmenutilecont">
                        <div className="mainmenutile" onClick={() => { lP("https://tonetwork.de/support.php") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faHeadset} />
                            Support
                        </div>
                        <div className="mainmenutile" onClick={() => { lP("https://tonetwork.de/datenschutz.php") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faScaleBalanced} />
                            Rechtliches
                        </div>
                        <div className="mainmenutile" onClick={() => { lP("/fueuf26/randomexam") }}>
                            <FontAwesomeIcon className="mainmenutileicon" icon={faBookOpenReader} />
                            FüUF26
                        </div>
                    </div>
                </div>



            </div >
    )
}